<template>
    <div>
        <section class="component -btn mb-4">
            <h3>Buttons</h3>
            <div class="component-grid">
                <div v-for="(value, name) in variants[visibleVariant].states" :key="name" class="mb-1">
                    {{ name }}:<br> 
                    <Btn :cssClasses="getClasses(value)" :disabled="value.disabled" v-html="getLabel()"></Btn>
                </div>
            </div>
        </section>
        <div class="mb-2">
            Variant: <component-selector :selections="Object.keys(variants)" @updateValue="toggleVisibleVariant"></component-selector>
        </div>
        <div class="mb-2" v-for="(value, name) in variants[visibleVariant].selectors" :key="name">
            {{ name }}: <component-selector :selections="value" @updateValue="toggleVisibleSize"></component-selector>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Btn from '@/components/atoms/Button.vue'
    import ComponentSelector from '@/components/design-library/components/ComponentSelector.vue'

    export default {
        name: 'Buttons',
        components: {
            Btn,
            ComponentSelector
        },
        data(){
            let sizeSelectors = [
                'Large', 'Medium', 'Small'
            ];
            return {
                visibleVariant: 'Primary',
                visibleSize: 'Large',
                sizes: {
                    'Large': 'btn',
                    'Medium': 'btn btn-sm',
                    'Small': 'btn btn-xs'
                },
                variants: {
                    'Primary': { 
                        name: 'Primary',
                        selectors: { 
                            Size: sizeSelectors
                        },
                        states: {
                            'Normal': {
                                classes: 'btn-primary',
                                disabled: false
                            },
                            'Hover': {
                                classes: 'btn-primary btn-primary-hovered',
                                disabled: false
                            },
                            'Disabled': {
                                classes: 'btn-primary',
                                disabled: true
                            }
                        }
                    },
                    'Default': { 
                        name: 'Default',
                        selectors: { 
                            Size: sizeSelectors 
                        },
                        states: {
                            'Normal': {
                                classes: 'btn-default',
                                disabled: false
                            },
                            'Hover': {
                                classes: 'btn-default btn-default-hovered',
                                disabled: false
                            }
                        }
                    },
                    'Outline': { 
                        name: 'Outline',
                        selectors: { 
                            Size: sizeSelectors 
                        },
                        states: {
                            'Normal': {
                                classes: 'btn-outline',
                                disabled: false
                            },
                            'Hover': {
                                classes: 'btn-outline btn-outline-hovered',
                                disabled: false
                            }
                        }
                     },
                    'Danger Outline': { 
                        name: 'Danger Outline',
                        selectors: { 
                            Size: sizeSelectors 
                        },
                        states: {
                            'Normal': {
                                classes: 'btn-outline-danger',
                                disabled: false
                            },
                            'Hover': {
                                classes: 'btn-outline-danger btn-outline-danger-hovered',
                                disabled: false
                            },
                            'Disabled': {
                                classes: 'btn-outline-danger',
                                disabled: true
                            }
                        }
                     }/*,
                    'Next': { 
                        selectors: {
                            Size: [ sizeSelectors[0], sizeSelectors[2] ]
                        },
                        cssClass: 'btn-next',
                        hasDisabled: false
                    },
                    'Previous': { 
                        selectors: {
                            Size: [ sizeSelectors[0], sizeSelectors[2] ]
                        },
                        cssClass: 'btn-prev',
                        hasDisabled: false
                     } */
                }
            }
        },
        methods: {
            toggleVisibleVariant: function(value){
                this.visibleVariant = value;
            },

            toggleVisibleSize: function(value){
                this.visibleSize = value;
            },
            getLabel: function(){
                if(this.visibleVariant === 'Next'){
                    return '<i class="icon-angle-right-ut"></i>'
                } else if (this.visibleVariant === 'Previous'){
                    return `<i class="icon-angle-left-ut"></i>`;
                } else {
                    return this.visibleVariant;
                }
            },
            getClasses(value){
                return  `${value.classes} ${this.sizes[this.visibleSize]}`;
            }
        }
    }
</script>