<template>
  <div class="components-page">
    <header>

    </header>
    <main>
      <div class="left-nav">
        <ul class="component-list">
          <li v-for="component in componentList" :key="component" class="component-item" :class="{ selected:component == visibleSection }" @click="toggleSection(component)">{{ component }}</li>
        </ul>
      </div>
      <div class="components">
        <component v-for="component in componentList" :key="component" :is="visibleSection"></component>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Buttons from '@/components/design-library/components/Buttons.vue'

export default {
  name: 'Components',
  components: {
    Buttons
  },
  data(){
    return {
      visibleSection: 'Buttons'
    }
  },
  computed: {
    componentList: function() {
      // filter out VueComponent
      return Object.keys(this.$options.components).filter(component => component !== 'Components');
    }
  },
  methods: {
    toggleSection: function(componentName){
      this.visibleSection = componentName;
    }
  }
}
</script>

<style scoped lang="scss">
  
</style>