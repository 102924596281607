<template>
  <button :class="cssClasses"><slot></slot></button>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    cssClasses: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  
</style>
