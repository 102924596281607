<template>
    <select @change="changedValue">
        <option v-for="s in selections" :key="s" :value="s">{{ s }}</option>
    </select>
</template>

<script>
export default {
  name: 'ComponentSelector',
  props: {
      selections: Array
  },
  methods: {
      changedValue($event){
          this.$emit('updateValue', $event.target.value);
      }
  }
}
</script>